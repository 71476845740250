<template>
    <div>

    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>