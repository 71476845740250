<template>
    <div>
      <h1>About Us</h1>
      <a href="https://www.facebook.com/groups/566123222313349/?hoisted_section_header_type=recently_seen&multi_permalinks=836935335232135" target="_blank" rel="noopener noreferrer" >Join our FB page</a>

    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>
  