<template>
    <div>
      <h1>To be updated with contacts</h1>
      <p>Contact: cfchouston@couplesforchristusa.org</p>

    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>