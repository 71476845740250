<template>
    <div>
      <h1>Couplese for Christ</h1>
      <h1>Singles for Christ</h1>
      <h1>Youths for Christ</h1>
      <h1>Kids for Christ</h1>
      <h1>Servants of the Lord</h1>
      <h1>Handmaids of the Lord</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>