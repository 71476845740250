<template>
    <div>
        <p>CFC South TX</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LandingPage',
  };
  </script>
  